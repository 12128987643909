.about_section{
    margin: auto;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    padding-bottom: 25px;
    padding-top: 50px;
    margin-bottom: 150px;
    transform: translateY(200px);
    background-color: rgb(24, 21, 21);
 
}

.about_container {
    color: #fff;
    display: flex;
    padding-left: 50px;
    padding-right: 30px;
    justify-content: space-between;
    align-items: center;
  }
  
  .about_card {
    width: 100%;
    font-size: 0.9rem;
    line-height: 150%;
  }
  .about_heading{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    gap: 0rem;
    text-transform: uppercase;
    
    font-weight: bold;
    transform: translateY(95px);
  }
  .about_card h1 {
    padding-bottom: 10px;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .about_card p {
    font-size: 110%;
  }
  
  .about_card a {
    font-weight: 54000;
   
  }
  
  @media screen and (max-width: 960px) {
    .about_card {
      font-size: 80%;
    }
  
    
  }
  
  @media screen and (max-width: 768px) {
    .about_card {
      font-size: 50%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .about_section {
      justify-content: flex-start;
      max-width: 70%;
    }
    .about_container {
      color: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px 20px;
      align-items: flex-start;
      justify-content: space-between;
    }
  
    .about_card {
      width: 100%;
      font-size: 0.8rem;
      line-height: 150%;
    }
    .about_card h1 {
      font-size: 1.5rem;
    }
  }
  